import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Firestore import karo

const firebaseConfig = {
  apiKey: 'AIzaSyA6stpGXlpptM0giH2sWLOK-YuINQDPCaQ',
  appId: '1:138107693250:web:816341e221307b9eaa335b',
  messagingSenderId: '138107693250',
  projectId: 'garvit-hindustan-ff581',
  authDomain: 'garvit-hindustan-ff581.firebaseapp.com',
  storageBucket: 'garvit-hindustan-ff581.appspot.com',
  measurementId: 'G-JTD8VZF615',
};

const App = initializeApp(firebaseConfig);
const firestore = getFirestore(App); // Firestore ko initialize karna

export { App, firestore }; // Dono ko export karo
