import React from "react";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

// Protected Route component
const ProtectedRoute = ({ children }) => {
    const auth = getAuth();
    const user = auth.currentUser; // Get the current user

    if (!user) {
        // If user is not logged in, redirect to login page
        return <Navigate to="/login" />;
    }

    // If user is logged in, allow access to the route
    return children;
};

export default ProtectedRoute;
