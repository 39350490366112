import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Mysignup from "./pages/mysignup/Mysignup";
import Newlogin from "./pages/newlogin/Newlogin";
import Dashboard from "./pages/dashboard/Dashboard";
import ProtectedRoute from "../src/ProtectedRoute.js"; // Import ProtectedRoute
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Mysignup />} />
        <Route path="/login" element={<Newlogin />} />

        {/* Protected route */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
