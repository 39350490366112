import React, { useState, useEffect } from 'react';
import '../header/Header.css'; // Importing CSS file


import logos from '../../images/10.png';
import newlogo from '../../images/newlogo.png';
import nomim from '../../images/9.png';

const Header = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const date = now.toLocaleDateString('hi-IN'); // Hindi format ke liye
      const time = now.toLocaleTimeString('hi-IN');
      setCurrentDate(date);
      setCurrentTime(time);
    };

    updateTime(); // Initial update
    const timerId = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup interval on unmount
  }, []);

  return (
    <div className='container-fluid header'>
      <div className='row firstrow'>
        <div className='col-md-6'>
          <div className='mainhding'>
            <p>
              E-Mail: <a href="mailto:garvithindustan@gmail.com">garvithindustan@gmail.com</a>{' '}
              <span>भरतपुर डाक पंजीयन : भरतपुर/95/23</span>
            </p>
          </div>
          <div className='rate'>
            <p>
              RAJHIN/83334 वर्ष - 3 <span>अंक - 268 </span><span>प्रसार संख्या - 8000 </span><span>मूल्य 3 रु</span>
            </p>
          </div>
        </div>
        <div className='col-md-6'>
        <div className="social-iconss">
                        <a href="#"><i className="fab fa-facebook-f mx-2"></i></a>
                        <a href="#"><i className="fab fa-twitter mx-2"></i></a>
                        <a href="#"><i className="fab fa-instagram mx-2"></i></a>
                        <a href="#"><i className="fab fa-linkedin-in mx-2"></i></a>
                    </div>
        </div>
      </div>
      <div className='row secondrow'>
        <div className='col-md-3'>
          <img src={logos} alt='' />
        </div>
        <div className='col-md-6'>
          <img src={newlogo} alt='' />
        </div>
        <div className='col-md-3'>
          <img src={nomim} alt='' />
        </div>
      </div>
      <div className='row thirdrow'>
        <div className='col-md-3'>
          <ul>
            <li>Home</li>
            <li>About</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className='col-md-6'>
          <ul>
            <li>होम</li>
            <li>चुनाव</li>
            <li>भारत</li>
            <li>मनोरंजन</li>
            <li>धर्म</li>
            <li>लाइफस्टाइल</li>
            <li>खेल</li>
          </ul>
        </div>
        <div className='col-md-3'>
          <p>{`Date: ${currentDate} | Time: ${currentTime}`}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
