import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { App } from "../../firebase";
import { useNavigate } from "react-router-dom"; // For navigation


import Logo from '../../images/logo.png'

// Firebase Authentication setup
const auth = getAuth(App);

const Newlogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate(); // For redirecting after successful login

    const signinUser = async (e) => {
        e.preventDefault();
        setError(""); // Clear previous errors

        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log("Signin Successful");
            navigate("/dashboard"); // Redirect to dashboard
        } catch (error) {
            setError(error.message); // Show error if login fails
        }
    };

    return (
        <div className="signup-container">
            <form onSubmit={signinUser}>
            <div className="logocnt">
                <img src={Logo} alt=""></img>
                </div>
                <div className="emgap">
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div  className="pwdgap">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Sign In</button>
            </form>

            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Newlogin;
