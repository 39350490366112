import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { App } from "../../firebase";
import { Link } from "react-router-dom"; // Link for routing to login page
import '../../pages/mysignup/Mysignup.css';

import Logo from '../../images/logo.png'

// Firebase Authentication setup
const auth = getAuth(App);

const Mysignup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const signupUser = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setError(""); // Clear any previous errors
        setSuccess(false); // Reset success message

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setSuccess(true); // Set success to true if signup is successful
            setEmail(""); // Clear email field
            setPassword(""); // Clear password field
        } catch (error) {
            setError(error.message); // Set error message if signup fails
        }
    };

    return (
        <div className="signup-container">
            <form onSubmit={signupUser}>
                <div className="logocnt">
                <img src={Logo} alt=""></img>
                </div>
                <div className="emgap">
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="pwdgap">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Sign Up</button>
            </form>
            
            {error && <p className="error">{error}</p>}
            {success && (
                <div>
                    <p className="success">Sign up successful!</p>
                    <Link to="/login">Already have an account? Log in here</Link>
                </div>
            )}

            {!success && (
                <div>
                    <Link to="/login">Already signed up? Go to login</Link>
                </div>
            )}
        </div>
    );
};

export default Mysignup;
